import axios from 'axios';
import { requestOptions } from './UserToken';

//test url
// axios.defaults.baseURL = `http://154.56.60.23:8080/remittance/v1/api/`;

axios.defaults.baseURL = `https://app.gurugfood.com:8444/remittance/v1/api/`;

//live url
// axios.defaults.baseURL = 'https://laxmipay.ausnepit.me:8444/remittance/v1/api/';

https: if (!!localStorage.getItem('Token')) {
  axios.defaults.headers.common['Authorization'] = localStorage.getItem('Token');
}

export const setLocal = Token => {
  axios.defaults.headers.common['Authorization'] = Token;
};
const login = data => {
  return axios.post(`admin/login`, data);
};
const EditRolesnPermission = data => {
  return axios.post(`admin/roles-and-permissions/edit`, data);
};
const changePassword = data => {
  return axios.put(`admin/change-password`, data);
};
const register = data => {
  return axios.post(`admin/register`, data);
};

const registerUpdate = data => {
  return axios.put(`admin/update`, data);
};

const adminData = data => {
  return axios.get(`admin/list`, data);
};

const CustomerLists = data => {
  return axios.post(`customer/admin/get-customer`, data);
};
const FindCustomer = data => {
  return axios.post(`admin/customer/find-username`, data);
};
const FindCode = data => {
  return axios.post(`admin/transaction/search/payment-activity-code`, data);
};
const ViewMore = id => {
  return axios.get(`admin/customer/get-user-by-id/${id}`);
};
const VerifyCustomerLists = data => {
  return axios.put(`customer/admin/verify-customer`, data);
};
const VerifyCloseTicket = data => {
  return axios.post(`customer-query/admin/close`, data);
};
const replyToTicket = data => {
  return axios.post(`customer-query/admin/reply`, data);
};

const addDiscountCommission = data => {
  return axios.post(`admin/commission/offer/discount/add`, data);
};

const discountCommissionUpdate = data => {
  return axios.post(`admin/commission/offer/discount/edit`, data);
};

const listdiscountCommission = () => {
  return axios.get(`admin/commission/offer/discount/get`);
};
const advertisement = data => {
  return axios.post(`admin/advertisement`, data);
};

const CustomerSupport = data => {
  return axios.post(`customer-query/admin/get-pending-ticket`, data);
};
const ManageAid = data => {
  return axios.post(`admin/promotion/list`, data);
};
const AidInquiry = data => {
  return axios.post(`promotion-inquiry/list`, data);
};
const ListPlan = () => {
  return axios.get(`admin/premium-plans`);
};

const ListPaymentMobile = data => {
  return axios.post(`payment/list`, data);
};

const ViewMorePlanMobile = id => {
  return axios.get(`payment/detail/${id}`);
};

const CreateCustomeApi = (path, data) => {
  return axios.post(path, data);
};
// graph api

const GraphMale = () => {
  return axios.get(`admin/countByGender/Male`);
};
const GraphFemale = () => {
  return axios.get(`admin/countByGender/Female`);
};
const GraphOthers = () => {
  return axios.get(`admin/countByGender/Others`);
};
const GraphCountryList = () => {
  return axios.get(`admin/country`);
};
const GraphCountryCount = value => {
  return axios.get(`admin/country/${value}`);
};

//graph api end
const listRoles = () => {
  return axios.get(`admin/role/list`);
};
const listPermissions = () => {
  return axios.get(`admin/permission/list`);
};
const listCoins = () => {
  return axios.get(`admin/coin`);
};

const CoinsAdd = data => {
  return axios.post(`admin/coin`, data);
};
const CoinsUpdate = data => {
  return axios.put(`admin/coin`, data);
};
const PlansUpdate = data => {
  return axios.put(`admin/premium-plans`, data);
};
const TotalUsers = () => {
  return axios.get(`admin/customer/count-all`);
};
const TotalTranscations = () => {
  return axios.get(`admin/transaction/count-payments`);
};
const TotalTrial = () => {
  return axios.get(`admin/count-trial `);
};

const TotalTransferredAmount = () => {
  return axios.get(`admin/transaction/add/payment-amount`);
};
const TotalToday = () => {
  return axios.get(`admin/count/today-added-user `);
};
const DropDown = () => {
  return axios.get(`admin/role/drop-down/register`);
};
const RoleAdd = data => {
  return axios.post(`admin/role/add`, data);
};
const PermissionAdd = data => {
  return axios.post(`admin/permission/add`, data);
};

const PlanAdd = data => {
  return axios.post(`admin/premium-plans`, data);
};
const Mass = data => {
  return axios.post(`admin/mass-notification`, data);
};

const discountCommissionDisable = id => {
  return axios.get(`admin/commission/offer/discount/disable/${id}`);
};
const discountCommissionActivate = id => {
  return axios.get(`admin/commission/offer/discount/enable/${id}`);
};
const ReportReasonsDisable = id => {
  return axios.get(`report-reason/admin/delete/${id}`);
};
const SafetyTipDisable = id => {
  return axios.get(`safety-tip/delete/${id}`);
};
const SpecialistspDisable = id => {
  return axios.get(`specialist-list/admin/delete/${id}`);
};
const PromoActivate = data => {
  return axios.post(`admin/coupon-code/activate`, data);
};
const UserActivate = data => {
  return axios.post(`admin/activate-user`, data);
};
const UserDisable = data => {
  return axios.post(`admin/disable-user`, data);
};
const AdminActivate = id => {
  return axios.get(`admin/activate-admin/${id}`);
};
const AdminDisable = id => {
  return axios.get(`admin/deactivate-admin/${id}`);
};
const AddReportReasons = data => {
  return axios.get(`report-reason/admin/add`, data);
};
const AddSafetyTipType = data => {
  return axios.post(`admin/safety-tip-type/add`, data);
};
const ListSafetyTipType = () => {
  return axios.get(`admin/safety-tip-type/get-all`);
};
const ListSafetyTips = data => {
  return axios.post(`safety-tip/get-all`, data);
};
const AddSafetyTip = data => {
  return axios.post(`safety-tip/admin/add`, data);
};
const UpdateReportReasons = data => {
  return axios.post(`report-reason/admin/update`, data);
};
const ListReportReasons = () => {
  return axios.get(`report-reason/admin/get-all`);
};
const UpdateSafetyTipType = data => {
  return axios.post(`admin/safety-tip-type/update`, data);
};
const SafetyTipTypeDetail = id => {
  return axios.get(`admin/safety-tip-type/get-by-id/${id}`);
};
const ListSpecialists = () => {
  return axios.get(`specialist-list/user/get-all`);
};
const ListDoctor = data => {
  return axios.post(`admin/get/all-doctor`, data);
};

//remit

const AddRemitBranch = data => {
  return axios.post(`remittance/add`, data);
};
const UpdateRemitBranch = data => {
  return axios.post(`remittance/edit`, data);
};

const ListRemitBranch = () => {
  return axios.get(`remittance/get-list`);
};
const UpdateServiceCommission = data => {
  return axios.post(`admin/commission/edit`, data);
};

const DeleteServiceCommission = id => {
  return axios.get(`admin/commission/delete/${id}`);
};

const ListServiceCommission = () => {
  return axios.get(`admin/commission/get`);
};
// const ListBankBranch = () => {
//   return axios.get(`associated-banks/get-all?sort=descending`);
// };
const ListBankBranch = data => {
  return axios.get(`associated-banks/get-all?sort=descending&page=${data}`);
};
const UpdateBankBranch = data => {
  return axios.post(`associated-banks/edit`, data);
};
const AddExchangeRate = data => {
  return axios.post(`exchange-rate/set`, data);
};
const UpdateExchangeRate = data => {
  return axios.put(`exchange-rate/edit/rate`, data);
};
const UpdateRoles = data => {
  return axios.put(`admin/role/update`, data);
};
const ListExchangeRate = () => {
  return axios.get(`exchange-rate/get`);
};
const AddFaqSection = data => {
  return axios.post(`frequently-asked-question/admin/add`, data);
};
const ListFaqSection = () => {
  return axios.get(`frequently-asked-question/admin/get-all`);
};
const ListPaymentManagement = ({
  yourStatus,
  yourpage,
  yourpageSize,
  yoursort,
  yoursortParameter,
  fromDate,
  toDate,
}) => {
  return axios.get(
    `admin/transaction/list-all-payments?status=${yourStatus}&page=${yourpage}&pageSize=${yourpageSize}&sort=${yoursort}&sortParameter=${yoursortParameter}&fromDate=${fromDate}&toDate=${toDate}`
  );
};
const CommissionAdd = data => {
  return axios.post(`admin/commission/add`, data);
};
const UpdateRejectedPayment = data => {
  return axios.post(`admin/transaction/update-rejected-payment`, data);
};
const LoyaltyPointsSchemeAdd = data => {
  return axios.post(`loyalty-points/add`, data);
};
const LoyaltyPointsSchemeDisable = id => {
  return axios.get(`loyalty-points/delete/${id}`);
};
const ApproveRejectedPayment = id => {
  return axios.get(`admin/transaction/approve-payment/${id}`);
};
const ListLoyaltyPointsScheme = data => {
  return axios.post(`loyalty-points/get-all`, data);
};
const LoyaltyPointsMainAdd = data => {
  return axios.post(`admin/customer/loyalty-point/add`, data);
};
const ListLoyaltyPointsMain = () => {
  return axios.get(`admin/customer/loyalty-point/list`);
};
const LoyaltyPointsMainDisable = id => {
  return axios.get(`admin/customer/loyalty-point/delete/${id}`);
};

const UpdateLoyaltyPointsMain = data => {
  return axios.post(`admin/customer/loyalty-point/edit`, data);
};
const ViewPayment = id => {
  return axios.get(`admin/transaction/get-payment-by-id/${id}`);
};

const GetCurrentBalance = data => {
  return axios.get(
    `send-transaction/get-current-balance?agentCode=${data.agentCode}&userId=${data.userId}`
  );
};

const GetExchangeRate = data => {
  return axios.get(
    `send-transaction/get-exchange-rate?agentCode=${data.agentCode}&userId=${data.userId}&paymentType=${data.paymentType}&payoutCountry=${data.payoutCountry}&transferAmount=${data.transferAmount}&paymentMode=${data.paymentType}&calcBy=${data.calcBy}&location=${data.location}`
  );
};

const ReleaseSendTransaction = data => {
  return axios.post(`/send-transaction/send-transaction`, data);
};

const QueryTXNStatus = data => {
  return axios.get(`send-transaction/check-transaction-status?pinNO=${data}&transactionId=${''}`);
};

const AuthorizedConfirmedTransaction = data => {
  return axios.get(`send-transaction/authorize-transaction?pinNO=${data}`);
};

const GetAgentList = data => {
  return axios.get(
    `send-transaction/get-agent-list?agentCode=${data.agentCode}&userId=${data.userId}&paymentType=${data.paymentType}&payoutCountry=${data.payoutCountry}`
  );
};
const ActivateCustomer = id => {
  return axios.get(`admin/customer/activate-customer/${id}`);
};

const VerifyPayment = ({ id, yourDecision, yourReason }) => {
  return axios.get(
    `admin/transaction/decided-payment/${id}?decision=${yourDecision}&reason=Iwantto${yourReason}`
  );
};
const TransactionsGraph = () => {
  return axios.get(`admin/transaction/count?interval=DAILY`);
};
const EarningCompany = () => {
  return axios.get(`admin/transaction/add/final-transfer-fee`);
};
const AccessBased = () => {
  return axios.get(`admin/get-permissions`);
};
const GetStripeModeData = () => {
  return axios.get(`/get-poli-pay-key`);
};
const UpdateStripeModeData = data => {
  return axios.post(`/set-poli-pay-key`, data);
};

const GenerateTokenApi = () => {
  return axios.get('/zai-payment/generate-token');
};

const DigitalWalletApi = userId => {
  return axios.get(`/zai-payment/check-digital-account?userId=${userId}`);
};

const VirtualAccountApi = walletId => {
  return axios.get(`/zai-payment/get-virtual-accounts?digitalWalletId=${walletId}`);
};

const GeneratePayIdApi = data => {
  return axios.post(`/zai-payment/register-pay-id`, data);
};

const ReleasePaymentApi = data => {
  return axios.post(`/zai-payment/make-payment`, data);
};

export {
  login,
  changePassword,
  register,
  addDiscountCommission,
  CustomerLists,
  listdiscountCommission,
  CustomerSupport,
  TotalUsers,
  DropDown,
  RoleAdd,
  PlanAdd,
  listRoles,
  listCoins,
  GraphMale,
  GraphFemale,
  GraphOthers,
  CoinsAdd,
  advertisement,
  ViewMore,
  ViewMorePlanMobile,
  ListPlan,
  ListPaymentMobile,
  CoinsUpdate,
  PlansUpdate,
  ManageAid,
  TotalTranscations,
  TotalTrial,
  TotalToday,
  Mass,
  discountCommissionUpdate,
  discountCommissionDisable,
  PromoActivate,
  UserActivate,
  UserDisable,
  adminData,
  AdminActivate,
  AdminDisable,
  GraphCountryList,
  GraphCountryCount,
  AidInquiry,
  AddReportReasons,
  UpdateReportReasons,
  ListReportReasons,
  ReportReasonsDisable,
  AddSafetyTipType,
  ListSafetyTipType,
  UpdateSafetyTipType,
  SafetyTipTypeDetail,
  AddSafetyTip,
  ListSafetyTips,
  SafetyTipDisable,
  ListSpecialists,
  ListDoctor,
  SpecialistspDisable,
  AddRemitBranch,
  ListRemitBranch,
  UpdateRemitBranch,
  AddExchangeRate,
  ListExchangeRate,
  UpdateExchangeRate,
  ListBankBranch,
  UpdateBankBranch,
  AddFaqSection,
  ListFaqSection,
  ListPaymentManagement,
  PermissionAdd,
  listPermissions,
  UpdateRoles,
  VerifyCustomerLists,
  CommissionAdd,
  ListServiceCommission,
  UpdateServiceCommission,
  DeleteServiceCommission,
  LoyaltyPointsSchemeAdd,
  LoyaltyPointsSchemeDisable,
  ListLoyaltyPointsScheme,
  VerifyCloseTicket,
  replyToTicket,
  LoyaltyPointsMainAdd,
  ListLoyaltyPointsMain,
  LoyaltyPointsMainDisable,
  UpdateLoyaltyPointsMain,
  ViewPayment,
  GetCurrentBalance,
  GetAgentList,
  GetExchangeRate,
  VerifyPayment,
  FindCode,
  FindCustomer,
  TotalTransferredAmount,
  ActivateCustomer,
  TransactionsGraph,
  EarningCompany,
  discountCommissionActivate,
  UpdateRejectedPayment,
  ApproveRejectedPayment,
  EditRolesnPermission,
  AccessBased,
  ReleaseSendTransaction,
  QueryTXNStatus,
  AuthorizedConfirmedTransaction,
  registerUpdate,
  GenerateTokenApi,
  DigitalWalletApi,
  VirtualAccountApi,
  GeneratePayIdApi,
  ReleasePaymentApi,
  CreateCustomeApi,
};
export { axios };
